// extracted by mini-css-extract-plugin
export var about__content = "mobilePages-module--about__content--daf00";
export var about__contentHeader = "mobilePages-module--about__content-header--e6356";
export var about__headerName = "mobilePages-module--about__header-name--532be";
export var button = "mobilePages-module--button--e6b88";
export var downloadables__card = "mobilePages-module--downloadables__card--70527";
export var downloadables__content = "mobilePages-module--downloadables__content--af515";
export var downloadables__contentHeader = "mobilePages-module--downloadables__content-header--81be7";
export var downloadables__contentSubtitle = "mobilePages-module--downloadables__content-subtitle--a3454";
export var downloadables__tag = "mobilePages-module--downloadables__tag--c48b6";
export var faqs__content = "mobilePages-module--faqs__content--bd4d0";
export var remove = "mobilePages-module--remove--8199d";
export var slideIn = "mobilePages-module--slide-in--1301b";
export var sticker = "mobilePages-module--sticker--491ad";
export var wallpaper = "mobilePages-module--wallpaper--b3153";
export var window = "mobilePages-module--window--3d2bd";
export var window__titleText = "mobilePages-module--window__title-text--efd76";
export var work__content = "mobilePages-module--work__content--34899";
export var work__contentHeader = "mobilePages-module--work__content-header--134a9";
export var work__tag = "mobilePages-module--work__tag--2b90e";