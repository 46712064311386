import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import useSound from "use-sound"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons"

import * as styles from "./utils/mobilePages.module.scss"
import exitSfx from "../../../static/sounds/click_close.mp3"


const MobileWindow = ({ title, children }) => {
    const [playExitSound] = useSound(exitSfx, {volume: 0.3});

    return (
        <div className={classNames(styles["window"])}>
            <div className={classNames(
                styles["window__titleText"], 
                `card card-header py-2 pr-3
                is-flex is-align-items-center is-justify-content-space-between`)
            }>
                {title}
                <Link to="/" onMouseUp={playExitSound}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} className="has-text-white has-text-right"/>
                </Link>
            </div>
            {children}
        </div>
    )
}

export default MobileWindow