import React from "react"
import classNames from "classnames"
import ReactPlayer from "react-player"

import MobileWindow from "./MobileWindow"
import GalleryItem from "../GalleryItem"

import * as styles from "./utils/mobilePages.module.scss"
import tools from "../Windows/utils/tools.json"
import illustrations from "../Windows/utils/illustrations.json"
import animations from "../Windows/utils/animations.json"
import developments from "../Windows/utils/development.json"

const WorkPage = () => {
    return (
        <MobileWindow title="work">
            <div className={classNames("p-4", styles["work__content"])}>
                    <p className="has-background-light p-5 mt-2 mb-5">
                        <b>Accepting work offers via my <a href="mailto:hi@sharyap.com" rel="noopener noreferrer">work email!</a></b>
                        <br/>I do illustration, animation, web design, and web/app development. {":)"}
                    </p>

                    {/* Tools and Development Section */}
                    <div className={classNames(styles["work__contentHeader"], "has-text-centered")}>
                        TOOLS
                </div>
                    <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                        {tools.map((tool) => (
                            <p key={tool} className={classNames(styles["work__tag"])}>
                                {tool}
                            </p>
                        ))}
                    </div>
                    <hr/>
                    <div className={classNames(styles["work__contentHeader"], "has-text-centered")}>
                        DEVELOPMENT
                    </div>
                    <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                        {developments.map((development) => (
                            <p key={development} className={classNames(styles["work__tag"])}>
                                {development}
                            </p>
                        ))}
                    </div>
                    <hr/>

                    {/* Illustrations */}
                    <div>
                        <div className={classNames(styles["work__contentHeader"], "has-text-centered")}>
                            ILLUSTRATION
                        </div>
                        <div>
                            <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                                {illustrations.slice(0,9).map((illustration) => (
                                    <GalleryItem key={illustration.name} image={illustration}/>
                                ))}
                            </div>
                        </div>
                        <p className="mt-2 has-text-centered">
                            See more on{' '}
                            <a href="https://instagram.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Instagram
                            </a>
                            {', '}
                            <a href="https://twitter.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </p>
                    </div>
                    <hr/>

                    {/* Animations */}
                    <div className="mt-6">
                        <div className={classNames(styles["work__contentHeader"], "has-text-centered")}>
                            ANIMATION
                        </div>
                        <div>
                            <div className={classNames("is-flex is-flex-wrap-wrap is-justify-content-center")}>
                                {animations.slice(0,4).map((animation) => (
                                    <div key={animation.name} className="mt-4">
                                        <ReactPlayer
                                            url={animation.url}
                                            width="300px"
                                            height="200px"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className="mt-2 has-text-centered">
                            See more on{' '}
                            <a href="https://youtube.com/c/sharyap"
                            target="_blank" rel="noopener noreferrer">
                                YouTube
                            </a>
                            {', '}
                            <a href="https://twitter.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </p>
                    </div>
                    <hr/>

                    {/* Development */}
                    <div className="mt-6">
                        <div className={classNames(styles["work__contentHeader"], "has-text-centered")}>
                            DEVELOPMENT
                        </div>
                        <p className={classNames(styles["about__contentBody"], "content mx-2")}>
                            <ul>
                                <li>This site!</li>
                                <li>This{' '}
                                    <a href="https://bingusfan25.itch.io/bingusadventure"
                                    target="_blank" rel="noopener noreferrer">
                                        buggy game jam
                                    </a>    
                                {' '}I made with a friend!</li>
                            </ul>
                        </p>
                        <p className="mt-2 has-text-centered">
                            See more on{' '}
                            <a href="https://github.com/yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                GitHub
                            </a>
                        </p>
                    </div>
                </div>
        </MobileWindow>
    )
}
export default WorkPage